<div class="controls-wrapper">
  <div class="control plus" fxLayoutAlign="center center" (click)="changeZoom(true)"><img
    src="/assets/icons/zoom-in-out/plus.svg" alt="Zoom In Control"></div>
  <div class="control minus" fxLayoutAlign="center center" (click)="changeZoom(false)"><img
    src="/assets/icons/zoom-in-out/minus.svg" alt="Zoom Out Control"></div>
  <div class="control extent" fxLayoutAlign="center center" (click)="extentZoom()"><img
    src="/assets/icons/zoom-in-out/extent.svg" alt="Extent Control"></div>
  <div class="control location" fxLayoutAlign="center center" (click)="locateUser()"><img
    src="/assets/icons/zoom-in-out/location.svg" alt="Location Control"></div>

  <div class="control extent" fxLayoutAlign="center center" (click)="tools()"><img
    src="/assets/icons/zoom-in-out/tools.svg" alt="Extent Control"></div>
  <!-- <div id="layerRemove" class="control location" fxLayoutAlign="center center" (click)="clearLayers()"><img
    src="/assets/icons/zoom-in-out/off-layers.svg" alt="Location Control"></div> -->


  <div class="control1 location" fxLayoutAlign="center center" *ngIf="toolsShow" (click)="print()" title="Печать карты"><img
    src="/assets/icons/zoom-in-out/print.svg" alt="Location Control"></div>

  <div class="control2 location" fxLayoutAlign="center center" *ngIf="toolsShow"  (click)="screen()" title="Скрин"><img
    src="/assets/icons/zoom-in-out/screen.svg" alt="Location Control"></div>

<!--  <div class="control3 location" fxLayoutAlign="center center" *ngIf="toolsShow"  (click)="measureDistance()"><img-->
<!--    src="/assets/icons/zoom-in-out/measure_distance.svg" alt="Location Control"></div>-->

<!--  <div class="control4 location" fxLayoutAlign="center center" *ngIf="toolsShow"  (click)="measureArea()"><img-->
<!--    src="/assets/icons/zoom-in-out/measure_area.svg" alt="Location Control"></div>-->

  <div class="control5 location" fxLayoutAlign="center center" *ngIf="toolsShow" (click)="getCoordinates()"  title="{{'menu.marker' | translate}}"><img
    src="/assets/icons/zoom-in-out/get_coordinates.svg" alt="Location Control"></div>
</div>

<div class="title map-info" leaflet-browser-print-content style="display: none;">
  <span style="font-size: 14px;margin-top: 10px;"><b>РГИС Шымкент</b></span><br>
  <span class="map-info-size">Размер карты: 406mm x 175mm</span><br>
  <!-- <span class="map-info-scale">Масштаб: 1:50000</span><br> -->
  <span class="map-info-date">Дата: 15.07.2021 01:27</span><br>
  <span class="map-info-url">Ссылка на сайт: <a>https://openmap.geo-shym.kz/</a></span><br><br>

  <span style="font-size:14px; color: green;">Легенда</span><br>
  <div class="leg">
    <div *ngFor="let legendName of legendNames" fxLayout="row" fxLayoutGap="5px">
      <img class="map-info-img" [src]="legendName.src" [alt]="legendName.nameRu">
      <div class="map-info-leg">{{ legendName.nameRu }}</div>
    </div>
  </div>

</div>
