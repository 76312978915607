import {Component, ElementRef, OnDestroy, OnInit, ViewChild,} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import * as L from 'leaflet';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {environment} from '../../environments/environment';
import {SvgIconsService} from '../shared/services/svg-icons.service';
import {MapService} from '../shared/services/map.service';
import {AuthService} from './services/auth.service';
import {MapControlsService} from '../shared/services/map-controls.service';
import {LayersManagerService} from '../shared/services/layers-manager.service';
import siteSettings from '../shared/config/site-settings';
import {MapInitDrawGeomService} from '../shared/services/map-init-draw-geom.service';

@Component({
  selector: 'app-core',
  templateUrl: './core.component.html',
  styleUrls: ['./core.component.scss'],
})
export class CoreComponent implements OnInit, OnDestroy {
  title = 'shym-public-web';
  leftSidenavOpened = true;
  width = 352;
  @ViewChild('drawer', {static: false}) drawer: ElementRef;
  map: L.Map;
  marker;
  private destroy$ = new Subject();

  constructor(
    private svgIconsService: SvgIconsService,
    private mapService: MapService,
    private authService: AuthService,
    private router: Router,
    private mapControlSvc: MapControlsService,
    private layersManagerService: LayersManagerService,
    private route: ActivatedRoute,
    private elementRef: ElementRef,
    private mapInitDrawSvc: MapInitDrawGeomService

  ) {
  }

  toggleLeftSide(): void {

    this.leftSidenavOpened = !this.leftSidenavOpened;

    setTimeout(() => {

      if (this.width === 352) {
        this.width = 0;
      } else {
        this.width = 352;
      }

      this.map.invalidateSize();
    }, this.leftSidenavOpened ? 150 : 100);

  }

  ngOnInit(): void {
    setTimeout(() => {
      const map: L.Map = this.mapService.initMap('mapid');
      this.map = map;

      this.mapControlSvc.initCoordinates(map);
      // this.mapControlSvc.initLocateControl(this.map);
      // this.mapControlSvc.initPrint(this.map);
      this.mapControlSvc.initRuler(this.map);
      this.mapControlSvc.initMeasureControl(this.map);
      this.mapControlSvc.browserPrint(map);
      // this.mapControlSvc.initHead(this.map);
      this.mapService.getMap().addLayer(this.mapService.editableLayers);
      this.mapInitDrawSvc.initNewTools(this.map); // new

      this.map.on('dblclick', (e: any) => {
        this.layersManagerService.showGetFeatureInfo(this.map, e.latlng);

        if (this.marker) {
          this.map.removeLayer(this.marker);
        }

        if (siteSettings.isCoordinate) {
          this.marker = L.marker([e.latlng.lat, e.latlng.lng]);
          this.marker.addTo(map).bindPopup('<div style="padding:10px;">' + e.latlng.lat + ', ' + e.latlng.lng + '</div>').openPopup();
          // marker.addTo(map);
          siteSettings.isCoordinate = false;
        }
      });

    }, 100);
    // this.autoLogin();

    const queryParams = this.route.snapshot.queryParams;
    if (!Object.keys(queryParams).length) {
      this.router.navigate(['/layer-themes']);
    }
  }

  // autoLogin(): void {
  //   this.authService
  //     .login(`${environment.username}`, `${environment.password}`)
  //     .pipe(takeUntil(this.destroy$))
  //     .subscribe(
  //       (user: UserToken) => {
  //         this.authService.setToken(user ? user.access_token : null);
  //         this.authService.setUserId(user ? user.userId : null);
  //         // console.log('token token: ', this.authService.getToken());
  //         const queryParams = this.route.snapshot.queryParams;
  //         if (!Object.keys(queryParams).length) {
  //           this.router.navigate(['/layer-themes']);
  //         }
  //         console.log('user', user);
  //       },
  //       () => {
  //         // this.pending = false
  //       }
  //     );
  // }

  // d() {
  //   const drawnItems = new L.FeatureGroup();
  //   this.map.addLayer(drawnItems);
  //
  //   const illustrateControl = new L.Illustrate.Control({
  //     edit: {
  //       featureGroup: drawnItems
  //     }
  //   });
  //   this.map.addControl(illustrateControl);
  //
  //   drawnItems.addLayer(
  //     new L.Illustrate.Pointer(L.latLng(41.7868010411136, -87.60601043701172), [
  //       new L.Point(0, 0),
  //       new L.Point(100, -100),
  //       new L.Point(400, -100)
  //     ])
  //   );
  //   drawnItems.addLayer(
  //     new L.Illustrate.Pointer(
  //       L.latLng(41.80219068741082, -87.60648250579834),
  //       [new L.Point(0, 0), new L.Point(100, -100), new L.Point(400, -100)]
  //     )
  //   );
  //
  //   this.map.on('draw:created', (evt: any) => {
  //     // let type = evt.layerType,
  //     //   layer = evt.layer;
  //
  //     drawnItems.addLayer(evt.layer);
  //   });
  // }

  ngOnDestroy(): void {
    this.destroy$.next();
  }
}

export interface UserToken {
  access_token: string;
  expires_in: number;
  jti: string;
  token_type: string;
  userId: number;
}
