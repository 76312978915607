import { Injectable } from '@angular/core';

import * as L from 'leaflet';
import 'leaflet-draw';

// import

// import {GeoSearchControl, SearchControl} from 'leaflet-geosearch/dist';

import '../../../libs/leaflet/measure-path/leaflet-measure-path.js';

@Injectable({
  providedIn: 'root',
})
export class MapService {
  private map: L.Map;
  currentLayer: L.TileLayer;
  public editableLayers: L.FeatureGroup = new L.FeatureGroup();

  private drawActivated = false;
  private drawControls: L.Control.Draw = null;

  googleRoadLayer = L.tileLayer(
    'https://mt0.google.com/vt/lyrs=m&hl=ru&x={x}&y={y}&z={z}&s=Galileo',
    {
      maxZoom: 21,
      minZoom: 9,
      attribution: ``,
    }
  );
  googleHybridLayer = L.tileLayer(
    'https://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}',
    {
      maxZoom: 21,
      minZoom: 9,
      attribution: ``,
    }
  );
  osmLayer = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
    maxZoom: 21,
    minZoom: 9,
    attribution: ``,
  });
  googleSat = L.tileLayer('https://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}', {
    maxZoom: 21,
    minZoom: 9,
    attribution: ``
  });
  white = L.tileLayer('https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png', {
    maxZoom: 21,
    minZoom: 9,
    attribution: ``
  });
  whitemap = L.tileLayer('', {
    maxZoom: 21,
    minZoom: 9,
    attribution: ``
  });
  TwoGis = L.tileLayer('https://tile2.maps.2gis.com/tiles?x={x}&y={y}&z={z}', {
    maxZoom: 18,
    minZoom: 9,
    attribution: ``
  });
  // blue = L.tileLayer('https://s3.amazonaws.com/elevation-tiles-prod/normal/{z}/{x}/{y}.png', {
  //   maxZoom: 21,
  //   // minZoom: 7,
  //   attribution: ``
  // });


  constructor() {
  }

  getMap(): L.Map {
    // this.map.on('click', (e: any) => {
    //   this.layersManagerService.showGetFeatureInfo(this.map, e.latlng);
    // });
    return this.map;
  }

  public setMap(mapIn: L.Map) {
    this.map = mapIn;
  }
  
  public initMap(target: string): L.Map {
    const map = L.map(target, {
      zoomControl: false,
      attributionControl: false,
    }).setView([42.3417, 69.5901], 12)

    this.currentLayer = this.osmLayer.addTo(map);
    map.addLayer(this.editableLayers);

    const scaleControl = L.control.scale({
      position: 'bottomright',
      metric: true,
      imperial: false,
    });
    map.addControl(scaleControl);

    this.setMap(map);
    map.on('layeradd', (e: any) => { this.getLayerMeasureResultArea(map); });
    return map;
  }

  public clearEditableLayers(): void {
    if (this.editableLayers.getLayers().length > 0) {
      this.editableLayers.clearLayers();
    }
  }

  public getDrawControls(): any {
    return this.drawControls;
  }

  public setDrawControls(drawControls: L.Control.Draw): void {
    this.drawControls = drawControls;
  }

  // public clearMap(): void {
  //   const map = this.getMap();
  //   map.eachLayer((layer) => {
  //     if (this.currentLayer !== layer) {
  //       map.removeLayer(layer);
  //     }
  //   });
  // }
  getLayerMeasureResultArea(map: L.Map): void {
    map.eachLayer((lyr: any) => {
      if (lyr.options.className === 'layer-measure-resultarea') {
        this.showMeasurements(lyr);
      }
    });
  }

  showMeasurements(lyr: any): void {
    if (!lyr) {
      return;
    }
    setTimeout(() => {
      lyr.showMeasurements();
    }, 100);
  }

}
